import React, { useEffect, useState } from "react";
import { useMessageService } from "../../../services/auth.service";
import { RegistrationCode } from "src/models/api/registration-code";
import {
  Button,
  CircularProgress,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

const GenerateCode: React.FC = () => {
  const [codes, setCodes] = useState<RegistrationCode[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const { fetchRegistrationCodes, generateCode, markCodeAsUsed } =
    useMessageService();

  const fetchData = async () => {
    setLoading(true);
    try {
      const codes = await fetchRegistrationCodes();
      setCodes(codes);
    } catch (error) {
      console.error("Error fetching registration codes:", error);
      setError("Failed to fetch registration codes");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []); // Empty dependency array to run only once

  const handleGenerateCode = async () => {
    try {
      await generateCode();
      await fetchData();
    } catch (err) {
      setError("Failed to generate registration code");
    }
  };

  const handleMarkAsUsed = async (code: string) => {
    try {
      await markCodeAsUsed(code);
      await fetchData();
    } catch (err) {
      setError("Failed to mark code as used");
    }
  };

  if (loading) {
    return (
      <Container>
        <CircularProgress />
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <Typography color="error">{error}</Typography>
      </Container>
    );
  }

  return (
    <Container>
      <Typography variant="h3" gutterBottom>
        Registration Codes
      </Typography>
      <Button variant="contained" color="primary" onClick={handleGenerateCode}>
        Generate New Code
      </Button>
      <TableContainer component={Paper} style={{ marginTop: 20 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Code</TableCell>
              <TableCell>Expires At</TableCell>
              <TableCell>Is Used</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {codes.map((code) => (
              <TableRow key={code.Id}>
                <TableCell>{code.Id}</TableCell>
                <TableCell>{code.Code}</TableCell>
                <TableCell>
                  {new Date(code.ExpiresAt).toLocaleString()}
                </TableCell>
                <TableCell>{code.IsUsed.toString()}</TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={!!code.IsUsed} // Ensure IsUsed is a boolean
                    onClick={() => handleMarkAsUsed(code.Code)}
                  >
                    Mark as Used
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default GenerateCode;

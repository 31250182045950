import { AxiosRequestConfig } from "axios";
import { ApiResponse } from "../models/api-response";
import { createApiCallerWithToken } from "./external-api.service";
import { useAuthToken } from "../hooks/useAuthToken";
import { useNotification } from "../hooks/useNotification";
import { useState } from "react";
import { SEARCH_QUERY } from "../apiRoutes";
import { Item } from "../models/api/item"; // Import the Item and Category types

const apiServerUrl = process.env.REACT_APP_API_URL;

export const useFinderService = () => {
  const token = useAuthToken();
  const { showNotification } = useNotification();
  const [authAttempted, setAuthAttempted] = useState(false);

  const callApiWithToken = async (options: { config: AxiosRequestConfig }): Promise<ApiResponse> => {
    if (authAttempted) {
      showNotification("Authorization already attempted and failed", "error");
      return Promise.reject("Authorization already attempted and failed");
    }

    if (!token) {
      showNotification("You must log in to access this resource", "error");
      setAuthAttempted(true);
      return Promise.reject("Token is null");
    }

    const apiCaller = createApiCallerWithToken(token);
    return apiCaller(options);
  };

  const getQueries = async (sessionId: string): Promise<ApiResponse> => {
    const config: AxiosRequestConfig = {
      url: `${apiServerUrl}${SEARCH_QUERY}?sessionId=${sessionId}`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };

    return callApiWithToken({ config });
  };

  const fetchDataFromApi = async (sessionId: string): Promise<Item[]> => {
    try {
      const response = await getQueries(sessionId);
      if (response && Array.isArray(response.data)) return response.data as Item[];
      throw new Error("Failed to fetch data from API");
    } catch (error) {
      console.error("Error fetching items:", error);
      showNotification("Error fetching items. Please try again later.", "error");
      throw error;
    }
  };

  return {
    getQueries,
    fetchDataFromApi
  };
};    
import React from "react";
import { MobileNavBarTab } from "./mobile-nav-bar-tab";
import { useToken } from "../../../context/TokenProvider";
import { useRoutes } from "../../../context/RoutesProvider";

interface MobileNavBarTabsProps {
  handleClick: () => void;
}

export const MobileNavBarTabs: React.FC<MobileNavBarTabsProps> = ({
  handleClick,
}) => {
  const { roles } = useToken();
  const routes = useRoutes();

  if (!routes) {
    return null; // or you can return a loader if you prefer
  }

  return (
    <div className="mobile-nav-bar__tabs">
      {routes
        .filter(route => route.showInNavBar) // Filter routes based on showInNavBar
        .map(({ path, label, roles: routeRoles }) => {
          if (label && (!routeRoles || routeRoles.some(role => roles.includes(role)))) {
            return (
              <MobileNavBarTab
                key={path}
                path={path}
                label={label}
                handleClick={handleClick}
              />
            );
          }
          return null;
        })}
    </div>
  );
};
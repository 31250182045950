import React, { useEffect, useState, useRef } from "react";
import { CodeSnippet } from "../../../components/code-snippet";
import { PageLayout } from "../../../components/page-layout";
import { useMessageService } from "../../../services/auth.service";
import GenerateCode from "./generate-code";

export const AdminPage: React.FC = () => {
  const [message, setMessage] = useState<string>("");
  const hasFetched = useRef(false);
  const { getAdminResource } = useMessageService();

  useEffect(() => {
    let isMounted = true;

    const getMessage = async () => {
      if (hasFetched.current) return;
      hasFetched.current = true;

      try {
        const { data, error } = await getAdminResource();

        if (!isMounted) {
          return;
        }

        if (data) {
          setMessage(JSON.stringify(data, null, 2));
        }

        if (error) {
          setMessage(JSON.stringify(error, null, 2));
        }
      } catch (error) {
        if (isMounted) {
          setMessage(JSON.stringify(error, null, 2));
        }
      }
    };

    getMessage();

    return () => {
      isMounted = false;
    };
  }, [getAdminResource]);

  return (
    <PageLayout>
      <div>
        <h1>Admin Page</h1>
        <div>
          <p>
            <span>
              This page retrieves a <strong>Admin message</strong> from an
              external API.
            </span>
            <span>
              <strong>Only authenticated users can access this page.</strong>
            </span>
          </p>
          <GenerateCode />
        </div>
      </div>
    </PageLayout>
  );
};

// src/contexts/UserContext.tsx
import React, { createContext, useContext } from 'react';

interface User {
  role: string;
}

const UserContext = createContext<User | undefined>(undefined);

export const UserProvider: React.FC<{ user: User; children: React.ReactNode }> = ({ user, children }) => {
  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
};

export const useUser = (): User => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};
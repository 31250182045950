import React, { useState, useEffect } from "react";
import { useSearchService } from "../../services/search.service"; // Import the fetch functions
import { SearchOption } from "../../models/api/SearchOption";
import SearchForm from "./SearchForm"; // Import the SearchForm component
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button,
  ButtonGroup,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Alert,
  Grid,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Theme,
} from "@mui/material";
import { PageLoader } from "../../components/page-loader";
import { PageLayout } from "src/components/page-layout";
import { useUser } from "../../context/UserContext";
import useMediaQuery from "@mui/material/useMediaQuery";

interface SearchProps {
  env: string;
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof SearchOption;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  { id: "Id", numeric: true, disablePadding: false, label: "Id" },
  { id: "Keywords", numeric: false, disablePadding: false, label: "Keywords" },
  {
    id: "Conditions",
    numeric: false,
    disablePadding: false,
    label: "Conditions",
  },
  { id: "ListedIn", numeric: false, disablePadding: false, label: "Listed In" },
  {
    id: "IsDescriptionSearch",
    numeric: false,
    disablePadding: false,
    label: "Description Search",
  },
];

type Order = "asc" | "desc";

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: string | number | boolean },
  b: { [key in Key]: string | number | boolean }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof SearchOption
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof SearchOption) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell align="left" padding="normal">
          Actions
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

interface EnhancedTableToolbarProps {
  locations: string[];
  selectedLocation: string;
  setSelectedLocation: (location: string) => void;
}

function EnhancedTableToolbar({
  locations,
  selectedLocation,
  setSelectedLocation,
}: EnhancedTableToolbarProps) {
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
      aria-label="table toolbar"
    >
      <Typography
        sx={{ flex: "1 1 100%" }}
        variant="h6"
        id="tableTitle"
        component="div"
      ></Typography>

      <FormControl sx={{ minWidth: 200 }} aria-labelledby="filter-by-location">
        <InputLabel id="filter-by-location">Filter by Location</InputLabel>
        <Select
          value={selectedLocation}
          onChange={(e) => setSelectedLocation(e.target.value)}
          label="Filter by Location"
          inputProps={{ "aria-labelledby": "filter-by-location" }}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {locations.map((location) => (
            <MenuItem key={location} value={location}>
              {location}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Toolbar>
  );
}

export const SearchPage: React.FC<SearchProps> = ({ env }) => {
  const user = useUser();
  const [searchOptions, setsearchOptions] = useState<SearchOption[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [editData, setEditData] = useState<SearchOption | null>(null);
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof SearchOption>("Id");
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [selectedLocation, setSelectedLocation] = useState("");
  const { fetchSearchOptions, deleteSearchOption } = useSearchService();
  const [warning, setWarning] = useState<string | null>(null);
  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const handleEdit = (searchOption: SearchOption) => {
    setEditData(searchOption);
    setShowModal(true);
  };

  const handleCreate = () => {
    setEditData(null);
    setShowModal(true);
  };

  const handleDelete = async (id: number) => {
    try {
      const response = await deleteSearchOption(id);
      if (response.status === 204) {
        await fetchData();
      } else {
        console.error("Delete failed:", response.statusText);
      }
    } catch (error) {
      console.error("Error deleting search option:", error);
    }
  };

  const handleEditCallback = async () => {
    await fetchData();
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      let data;
      data = await fetchSearchOptions();

      setsearchOptions(data);
    } catch (error) {
      console.error("Error fetching search options:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [env]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof SearchOption
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - searchOptions.length) : 0;

  const filteredsearchOptions = selectedLocation
    ? searchOptions.filter((input) => input.ListedIn === selectedLocation)
    : searchOptions;

  const visibleRows = React.useMemo(
    () =>
      stableSort(filteredsearchOptions, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, filteredsearchOptions]
  );

  const locations = Array.from(
    new Set(searchOptions.map((input) => input.ListedIn))
  );

  useEffect(() => {
    if (user.role !== "admin" && searchOptions.length >= 10) {
      setWarning(
        "You have reached the maximum number of search options allowed."
      );
    } else {
      setWarning(null);
    }
  }, [user.role, searchOptions.length]);

  return (
    <PageLayout>
      <Box>
        {isLoading ? (
          <PageLoader />
        ) : (
          <Box sx={{ width: "100%" }}>
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: isSmallScreen ? "column" : "row",
              }}
            >
              <Box>
                <h1>
                  Manage <b>Search options</b>
                </h1>
              </Box>
              <Box>
                <Grid container spacing={2}>
                  <Grid item>
                    <EnhancedTableToolbar
                      locations={locations}
                      selectedLocation={selectedLocation}
                      setSelectedLocation={setSelectedLocation}
                    />
                  </Grid>

                  <Grid item>
                    <Button
                      variant="contained"
                      onClick={handleCreate}
                      data-toggle="modal"
                      startIcon={<AddCircleIcon />}
                      disabled={
                        user.role !== "admin" && searchOptions.length >= 10
                      }
                    >
                      Add new search option
                    </Button>
                  </Grid>
                </Grid>
                {warning && (
                  <Alert severity="warning" style={{ marginTop: "10px" }}>
                    {warning}
                  </Alert>
                )}
              </Box>
            </Box>
            <Paper sx={{ width: "100%", mb: 2 }}>
              <TableContainer>
                <Table
                  aria-labelledby="tableTitle"
                  size={dense ? "small" : "medium"}
                >
                  {!isSmallScreen && (
                    <EnhancedTableHead
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                      rowCount={searchOptions.length}
                      onSelectAllClick={() => {}}
                    />
                  )}
                  <TableBody>
                    {visibleRows.map((row, index) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.Id}
                        >
                          {isSmallScreen ? (
                            <>
                              <TableCell
                                style={{
                                  flexGrow: 1,
                                  flexBasis: 0,
                                  wordWrap: "break-word",
                                }}
                              >
                                <Box>
                                  <Typography variant="body2">
                                    <strong>ID:</strong> {row.Id}
                                  </Typography>
                                  <Typography variant="body2">
                                    <strong>Keywords:</strong> {row.Keywords}
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      maxWidth: "100%",
                                      overflow: "hidden",
                                      whiteSpace: "normal",
                                      wordWrap: "break-word",
                                    }}
                                  >
                                    <strong>Conditions:</strong>{" "}
                                    {row.Conditions}
                                  </Typography>
                                  <Typography variant="body2">
                                    <strong>Listed In:</strong> {row.ListedIn}
                                  </Typography>
                                  <Typography variant="body2">
                                    <strong>Description Search:</strong>{" "}
                                    {row.IsDescriptionSearch ? "Yes" : "No"}
                                  </Typography>
                                </Box>
                              </TableCell>
                              <TableCell
                                style={{ width: 100, wordWrap: "break-word" }}
                              >
                                <ButtonGroup variant="contained">
                                  <Button
                                    color="warning"
                                    onClick={() => handleEdit(row)}
                                    data-toggle="modal"
                                  >
                                    <EditIcon />
                                  </Button>
                                  <Button
                                    color="error"
                                    onClick={() => {
                                      if (
                                        window.confirm(
                                          "Are you sure you want to delete this item?"
                                        )
                                      ) {
                                        handleDelete(Number(row.Id));
                                      }
                                    }}
                                    data-toggle="modal"
                                  >
                                    <DeleteIcon />
                                  </Button>
                                </ButtonGroup>
                              </TableCell>
                            </>
                          ) : (
                            <>
                              <TableCell>{row.Id}</TableCell>
                              <TableCell>{row.Keywords}</TableCell>
                              <TableCell
                                style={{
                                  maxWidth: "300px",
                                  overflow: "hidden",
                                  whiteSpace: "normal",
                                  wordWrap: "break-word",
                                }}
                              >
                                {row.Conditions}
                              </TableCell>
                              <TableCell>{row.ListedIn}</TableCell>
                              <TableCell>
                                {row.IsDescriptionSearch ? "Yes" : "No"}
                              </TableCell>
                              <TableCell>
                                <Box>
                                  <ButtonGroup variant="contained">
                                    <Button
                                      color="warning"
                                      onClick={() => handleEdit(row)}
                                      data-toggle="modal"
                                    >
                                      <EditIcon />
                                    </Button>
                                    <Button
                                      color="error"
                                      onClick={() => {
                                        if (
                                          window.confirm(
                                            "Are you sure you want to delete this item?"
                                          )
                                        ) {
                                          handleDelete(Number(row.Id));
                                        }
                                      }}
                                      data-toggle="modal"
                                    >
                                      <DeleteIcon />
                                    </Button>
                                  </ButtonGroup>
                                </Box>
                              </TableCell>
                            </>
                          )}
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow
                        style={{
                          height: (dense ? 33 : 53) * emptyRows,
                        }}
                      >
                        <TableCell colSpan={9} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={filteredsearchOptions.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
            <FormControlLabel
              control={<Switch checked={dense} onChange={handleChangeDense} />}
              label="Dense padding"
            />
          </Box>
        )}
        {showModal && (
          <Box className="modal">
            <Box className="modal-content">
              <span className="close" onClick={() => setShowModal(false)}>
                &times;
              </span>
              <SearchForm
                showModal={showModal}
                setShowModal={setShowModal}
                editData={editData}
                handleEditCallback={handleEditCallback} // Pass the callback function
              />
            </Box>
          </Box>
        )}
      </Box>
    </PageLayout>
  );
};

import { ComponentType } from 'react';
import { HomePage } from './pages/home/home-page';
import { ProfilePage } from './pages/profile/profile-page';
import { FinderPage } from './pages/finder/finder-page';
import { SearchPage } from './pages/search/search-page';
import { CallbackPage } from './pages/globals/callback-page';
import { NotFoundPage } from './pages/globals/not-found-page';
import { ApplyRolePage } from './pages/rolebased/applyrole/applyrole-page';
import { StartPage } from './pages/home/start';
import { AdminPage } from './pages/rolebased/admin/admin-page';
import { UserProvider } from './context/UserContext';

import HomeIcon from '@mui/icons-material/Home';
import StartIcon from '@mui/icons-material/PlayArrow';
import FinderIcon from '@mui/icons-material/Search';
import ListIcon from '@mui/icons-material/List';
import AdminIcon from '@mui/icons-material/AdminPanelSettings';
import CallbackIcon from '@mui/icons-material/Call';
import NotFoundIcon from '@mui/icons-material/Error';
import ApplyRoleIcon from '@mui/icons-material/AssignmentInd';
import { AccountCircle } from '@mui/icons-material';

interface RouteConfig {
  path: string;
  component: ComponentType<any>;
  label?: string;
  roles?: string[];
  exactRoles?: boolean;
  icon?: ComponentType<any>;
  showInNavBar?: boolean;
}

const routesConfig: RouteConfig[] = [
  { path: '/', component: HomePage, label: 'Home', icon: HomeIcon, showInNavBar: true },
  { path: '/start', component: StartPage, label: 'Start', roles: ['user'], icon: StartIcon, showInNavBar: true },
  { path: '/finder', component: FinderPage, label: 'Finder', roles: ['user'], icon: FinderIcon, showInNavBar: true },
  {
    path: '/searchOptions',
    component: (props: any) => (
      <UserProvider user={{ role: 'user' }}>
        <SearchPage {...props} />
      </UserProvider>
    ),
    label: 'Search options',
    roles: ['user'],
    icon: ListIcon,
    showInNavBar: true,
  },
  { path: '/profile', component: ProfilePage, label: 'Profile', roles: ['guest'], icon: AccountCircle, showInNavBar: false },
  { path: '/admin', component: AdminPage, label: 'Admin', roles: ['admin'], icon: AdminIcon, showInNavBar: false },
  { path: '/callback', component: CallbackPage, icon: CallbackIcon, showInNavBar: false },
  { path: '*', component: NotFoundPage, icon: NotFoundIcon, showInNavBar: false },
  {
    path: '/applyrole',
    component: ApplyRolePage,
    label: 'ApplyRole',
    roles: ['guest'],
    exactRoles: true,
    icon: ApplyRoleIcon,
    showInNavBar: true,
  },
];

// Function to get filtered routes based on user roles
export const getFilteredRoutes = (userRoles: string[]): RouteConfig[] => {
  var routes = 
   routesConfig.filter((route) => {
    if (!route.roles) return true; // Include routes without roles
    if (route.exactRoles) {
      return (
        route.roles.length === userRoles.length &&
        route.roles.every((role) => userRoles.includes(role))
      );
    }
    return route.roles.some((role) => userRoles.includes(role));
  });

  return routes;
};

export { routesConfig };
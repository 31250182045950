import React from "react";
import { IconButton, Tooltip, Menu, MenuItem, Typography, Box } from "@mui/material";
import { AccountCircle } from "@mui/icons-material";
import { useToken } from "../../../context/TokenProvider";
import { useNavigate } from "react-router-dom";

interface UserMenuProps {
  anchorElUser: null | HTMLElement;
  handleOpenUserMenu: (event: React.MouseEvent<HTMLElement>) => void;
  handleCloseUserMenu: () => void;
}

const settings = ["Profile"];

export const UserMenu: React.FC<UserMenuProps> = ({
  anchorElUser,
  handleOpenUserMenu,
  handleCloseUserMenu,
}) => {
  const { user } = useToken();
  const navigate = useNavigate();

  if (!user) {
    return null; // Do not render the UserMenu if the user is not authenticated
  }

  const handleMenuItemClick = (setting: string) => {
    handleCloseUserMenu();
    if (setting === "Profile") {
      navigate("/profile");
    }
  };

  return (
    <Box sx={{ flexGrow: 0 }}>
      <Tooltip title="Open settings">
        <IconButton
          size="large"
          edge="end"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleOpenUserMenu}
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <MenuItem
          sx={{
            backgroundColor: "primary.main",
            color: "white",
            "&:hover": {
              backgroundColor: "primary.dark",
            },
            cursor: "default",
          }}
        >
          <Typography variant="body1" component="div" sx={{ marginLeft: 2 }}>
            Hello, {user.email}
          </Typography>
        </MenuItem>
        {settings.map((setting) => (
          <MenuItem key={setting} onClick={() => handleMenuItemClick(setting)}>
            <Typography sx={{ textAlign: "center" }}>{setting}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
import React from "react";
import { Container, Typography, Box, Button } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { useToken } from "../../context/TokenProvider"; // Import useToken hook
import "./home-page.css";

export const HomePage: React.FC = () => {
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();
  const navigate = useNavigate();
  const { roles } = useToken(); // Get roles using useToken hook

  return (
    <Container maxWidth="lg" className="homepage-container">
      <Box className="homepage-box">
        <Typography
          variant="h2"
          component="h1"
          className="homepage-title"
          gutterBottom
        >
          Welcome to Market Magnet
        </Typography>
        <Typography
          variant="h5"
          component="h2"
          className="homepage-subtitle"
          gutterBottom
        >
          Track new eBay listings effortlessly
        </Typography>
        <Box className="homepage-buttons">
          {!isAuthenticated ? (
            <>
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={() => loginWithRedirect()}
                className="homepage-button"
              >
                Get Started
              </Button>
              {process.env.REACT_APP_ENVIRONMENT === "local" && (
                <Button
                  variant="outlined"
                  color="primary"
                  size="large"
                  onClick={() =>
                    loginWithRedirect({ screen_hint: "signup" } as any)
                  }
                >
                  Sign Up
                </Button>
              )}
            </>
          ) : (
            <>
              {roles.includes("user") && (
                <Button
                  variant="contained"
                  color="secondary"
                  size="large"
                  onClick={() => navigate("/start")}
                  className="homepage-button"
                >
                  Go to start guide
                </Button>
              )}
              {roles.includes("guest") && roles.length === 1 && (
                <Box>
                  <p>
                    Do you have a registration code? Then apply for a new role.
                  </p>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    onClick={() => navigate("/applyrole")}
                    className="homepage-button"
                  >
                    Register for a new role
                  </Button>
                </Box>
              )}
            </>
          )}
        </Box>
      </Box>
    </Container>
  );
};

import React from "react";
import { AppBar, Toolbar, Typography, Box, CircularProgress } from "@mui/material";
import { useToken } from "../../../context/TokenProvider";
import { useRoutes, useRoutesLoading } from "../../../context/RoutesProvider";
import { NavBarTab } from "./nav-bar-tab";
import { NavBarButtons } from "./nav-bar-buttons";
import { useMediaQuery } from "@mui/material";
import { UserMenu } from "../base/UserMenu";
import { DarkModeToggle } from "../base/DarkModeToggle";

interface NavBarProps {
  onToggleDarkMode: () => void;
  darkMode: boolean;
}

const NavBarTabs: React.FC = () => {
  const { roles } = useToken();
  const routes = useRoutes();
  const routesLoading = useRoutesLoading();

  if (routesLoading) {
    return <CircularProgress color="inherit" />; // Show a loader while routes are loading
  }

  if (!routes) {
    return null; // or you can return a loader if you prefer
  }

  return (
    <Box className="nav-bar__tabs" sx={{ display: "flex" }}>
      {routes
        .filter(route => route.showInNavBar) // Filter routes based on showInNavBar
        .map(({ path, label, roles: routeRoles }) => {
          if (
            label &&
            (!routeRoles || routeRoles.some((role) => roles.includes(role)))
          ) {
            return <NavBarTab key={path} path={path} label={label} />;
          }
          return null;
        })}
    </Box>
  );
};

export const NavBar: React.FC<NavBarProps> = ({
  onToggleDarkMode,
  darkMode,
}) => {
  const { user } = useToken(); // Assuming useToken provides user information
  const isMobile = useMediaQuery("(max-width: 1000px)"); // iPad or lower
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          MarketMagnet
        </Typography>
        <NavBarTabs />
        <NavBarButtons />
        <Box flexGrow={1} />
        <DarkModeToggle onToggleDarkMode={onToggleDarkMode} darkMode={darkMode} />
        {isMobile ? (
          <UserMenu
            anchorElUser={anchorElUser}
            handleOpenUserMenu={handleOpenUserMenu}
            handleCloseUserMenu={handleCloseUserMenu}
          />
        ) : (
          user && (
            <Typography variant="body1" component="div" sx={{ marginLeft: 2 }}>
              Hello, {user.email}
            </Typography>
          )
        )}
      </Toolbar>
    </AppBar>
  );
};
import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
} from "@mui/material";
import { useSearchService } from "../../services/search.service"; // Import the fetch functions

interface SearchOption {
  Id: number;
  Timestamp: string;
  Username: string;
  Keywords: string;
  MinPrice: number;
  MaxPrice: number;
  Conditions: string;
  SearchMode: string;
  ListedIn: string;
  KeySeller: string;
  MinFeedback: string;
  IsDescriptionSearch: boolean;
  Categories: string;
  LocatedIn: string;
  ExcludeSellers: string;
}

const optionsCondition = [
  { value: "new", name: "New" },
  { value: "newOther", name: "New Other" },
  { value: "newWithDefects", name: "New with Defects" },
  { value: "manufacturerRefurbished", name: "Manufacturer Refurbished" },
  { value: "excellentRefurbished", name: "Excellent Refurbished" },
  { value: "veryGoodRefurbished", name: "Very Good Refurbished" },
  { value: "goodRefurbished", name: "Good Refurbished" },
  { value: "sellerRefurbished", name: "Seller Refurbished" },
  { value: "likeNew", name: "Like New" },
  { value: "used", name: "Used" },
  { value: "veryGood", name: "Very Good" },
  { value: "good", name: "Good" },
  { value: "acceptable", name: "Acceptable" },
  { value: "forPartsOrNotWorking", name: "For Parts or Not Working" },
];

const ebaySite = [
  { value: "EBAY_US", name: "United States" },
  { value: "EBAY_GB", name: "United Kingdom" },
  { value: "EBAY_DE", name: "Germany" },
  { value: "EBAY_AU", name: "Australia" },
  { value: "EBAY_CA", name: "Canada" },
  { value: "EBAY_FR", name: "France" },
  { value: "EBAY_IT", name: "Italy" },
  { value: "EBAY_ES", name: "Spain" },
  { value: "EBAY_NL", name: "Netherlands" },
  { value: "EBAY_BE", name: "Belgium" },
  { value: "EBAY_AT", name: "Austria" },
];

interface SearchFormProps {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  editData: SearchOption | null;
  handleEditCallback: () => void;
}

const SearchForm: React.FC<SearchFormProps> = ({
  showModal,
  setShowModal,
  editData,
  handleEditCallback,
}) => {
  const { putSearchOption, postSearchOption } = useSearchService(); // Import the fetch functions
  const [localEditData, setLocalEditData] = useState<SearchOption | null>(
    editData
  );
  const [isSaveClicked, setIsSaveClicked] = useState(false);

  useEffect(() => {
    setLocalEditData(editData);
  }, [editData]);

  const handleSaveEdit = async () => {
    setIsSaveClicked(true);
    if (localEditData && validateForm()) {
      try {
        if (editData) {
          await putSearchOption(localEditData);
        } else {
          await postSearchOption(localEditData);
        }
        handleEditCallback();
        setShowModal(false);
      } catch (error) {
        console.error("Failed to save changes", error);
      }
    }
  };

  const validateForm = () => {
    return !!localEditData?.Keywords && !!localEditData?.ListedIn;
  };

  return (
    <Dialog open={showModal} onClose={() => setShowModal(false)}>
      <DialogTitle>
        {editData ? "Edit Details" : "Create New Search Option"}
      </DialogTitle>
      <DialogContent>
        <FormControl fullWidth margin="normal">
          <TextField
            required
            label="Keywords"
            value={localEditData?.Keywords || ""}
            onChange={(e) =>
              setLocalEditData({
                ...localEditData,
                Keywords: e.target.value,
                IsDescriptionSearch: true
              } as SearchOption)
            }
            error={isSaveClicked && !localEditData?.Keywords}
            helperText={
              isSaveClicked &&
              !localEditData?.Keywords &&
              "Keywords is required"
            }
          />
        </FormControl>
        <FormControl fullWidth margin="normal">
          <InputLabel>Conditions, currently not working WIP.</InputLabel>
          <Select
            multiple
            value={localEditData?.Conditions?.split(",") || []}
            onChange={(e) => {
              const selectedOptions = e.target.value as string[];
              setLocalEditData({
                ...localEditData,
                Conditions: selectedOptions.join(","),
              } as SearchOption);
            }}
          >
            {optionsCondition.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>
            Selected Conditions: {localEditData?.Conditions || "None"}
          </FormHelperText>
        </FormControl>
        <FormControl fullWidth margin="normal" required error={isSaveClicked && !localEditData?.ListedIn}>
          <InputLabel>Listed In</InputLabel>
          <Select
            value={localEditData?.ListedIn || ""}
            onChange={(e) =>
              setLocalEditData({
                ...localEditData,
                ListedIn: e.target.value,
              } as SearchOption)
            }
          >
            {ebaySite.map((site) => (
              <MenuItem key={site.value} value={site.value}>
                {site.name}
              </MenuItem>
            ))}
          </Select>
          {isSaveClicked && !localEditData?.ListedIn && <FormHelperText>Listed In is required</FormHelperText>}
        </FormControl>
        <FormControl fullWidth margin="normal">
          <InputLabel>Is Description Search</InputLabel>
          <Select disabled
            value={"true"}
            onChange={(e) =>
              setLocalEditData({ ...localEditData, IsDescriptionSearch: e.target.value === "false" } as SearchOption)
            }
          >
            <MenuItem value="true">True</MenuItem>
            <MenuItem value="false">False</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => setShowModal(false)}>
          Close
        </Button>
        <Button variant="contained" onClick={handleSaveEdit} disabled={isSaveClicked && (!localEditData?.Keywords || !localEditData?.ListedIn)}>
          Save Changes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SearchForm;
import React from "react";
import { AppBar, Toolbar, IconButton, Drawer, List, Box } from "@mui/material";
import { Menu as MenuIcon, Close as CloseIcon } from "@mui/icons-material";
import { MobileNavBarButtons } from "./mobile-nav-bar-buttons";
import { MobileNavBarTabs } from "./mobile-nav-bar-tabs";
import { UserMenu } from "../base/UserMenu";
import { DarkModeToggle } from "../base/DarkModeToggle";

interface MobileNavBarProps {
  onToggleDarkMode: () => void;
  darkMode: boolean;
}

enum MobileMenuState {
  CLOSED = "closed",
  OPEN = "open",
}

enum MobileMenuIcon {
  CLOSE = "close",
  MENU = "menu",
}

export const MobileNavBar: React.FC<MobileNavBarProps> = ({
  onToggleDarkMode,
  darkMode,
}) => {
  const [mobileMenuState, setMobileMenuState] = React.useState<MobileMenuState>(
    MobileMenuState.CLOSED
  );
  const [mobileMenuIcon, setMobileMenuIcon] = React.useState<MobileMenuIcon>(
    MobileMenuIcon.MENU
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const isMobileMenuOpen = () => {
    return mobileMenuState === MobileMenuState.OPEN;
  };

  const closeMobileMenu = () => {
    document.body.classList.remove("mobile-scroll-lock");
    setMobileMenuState(MobileMenuState.CLOSED);
    setMobileMenuIcon(MobileMenuIcon.MENU);
  };

  const openMobileMenu = () => {
    document.body.classList.add("mobile-scroll-lock");
    setMobileMenuState(MobileMenuState.OPEN);
    setMobileMenuIcon(MobileMenuIcon.CLOSE);
  };

  const toggleMobileMenu = () => {
    if (isMobileMenuOpen()) {
      closeMobileMenu();
    } else {
      openMobileMenu();
    }
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const getToolbarBackgroundColor = () => {
    return darkMode ? "#333" : "#1976d2"; // Adjust colors as needed
  };

  const getDrawerBackgroundColor = () => {
    return darkMode ? "#333" : "#1976d2"; // Adjust colors as needed
  };

  return (
    <AppBar position="static">
      <Toolbar style={{ backgroundColor: getToolbarBackgroundColor() }}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={toggleMobileMenu}
        >
          {mobileMenuIcon === MobileMenuIcon.MENU ? (
            <MenuIcon />
          ) : (
            <CloseIcon />
          )}
        </IconButton>
        <Box flexGrow={1} />
        <DarkModeToggle onToggleDarkMode={onToggleDarkMode} darkMode={darkMode} />
        <UserMenu
          anchorElUser={anchorElUser}
          handleOpenUserMenu={handleOpenUserMenu}
          handleCloseUserMenu={handleCloseUserMenu}
        />
      </Toolbar>
      <Drawer
        anchor="left"
        open={isMobileMenuOpen()}
        onClose={closeMobileMenu}
        PaperProps={{
          style: {
            backgroundColor: getDrawerBackgroundColor(),
          },
        }}
      >
        <List>
          <MobileNavBarTabs handleClick={closeMobileMenu} />
          <MobileNavBarButtons />
        </List>
      </Drawer>
    </AppBar>
  );
};
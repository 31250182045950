const IgnoredCategorieIds = [
    "33715",
    "178027",
    "172517",
    "33716",
    "33717",
    "33640",
    "33654",
    "32893",
    "45734",
    "40820",
    "33633",
    "259280",
    "122930",
    "262042",
    "260330",
    "162932",
    "133705",
    "4701",
    "4067",
    "35560",
    "181722",
    "63506",
    "121472",
    "8768",
    "145488",
    "185033",
    "261186",
    "33702",
    "77285",
    "98847",
    "162078",
    "50445",
    "50445",
    "36175",
    "1207",
    "4001",
    "33644",
    "9886",
    "50647",
    "129187",
    "177753",
    "184666",
    "262304",
    "20705",
    "75725",
    "87089",
    "168763",
    "260311",
    "258588",
    "180344",
    "11737",
    "20725",
    "71487",
    "262087",
    "33709",
    "67653",
    "25846",
    "107876",
    "33919",
    "122689",
    "45220",
    "122689",
    "16705",
    "4959",
    "67159",
    "11848",
    "259187",
    "126191",
    "182909",
    "72211",
    "262205",
    "174062",
    "4770",
    "262072",
    "20702",
    "33639",
    "29223",
    "58167",
    "26211",
    "33563",
    "43982",
    "33700",
    "33557",
    "33629",
    "262161",
    "263231",
    "178019",
    "178023",
    "262189",
    "28162",
    "33627",
    "418",
    "25621",
    "21205",
    "262167",
    "174113",
    "33710",
    "180924",
    "175837",
    "33699"
  ];

  export default IgnoredCategorieIds;
import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Button, Box } from "@mui/material";

export const NavBarButtons: React.FC = () => {
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();

  const handleLogout = () => {
    logout({ logoutParams: { returnTo: window.location.origin } } as any);
  };

  return (
    <Box display="flex" alignItems="center" className="nav-bar__buttons">
      {!isAuthenticated && (
        <>
          {process.env.REACT_APP_ENVIRONMENT === 'local' && (
            <Button variant="contained" color="primary" onClick={() => loginWithRedirect({ screen_hint: 'signup' } as any)}>
              Sign Up
            </Button>
          )}
          <Button variant="contained" color="secondary" onClick={() => loginWithRedirect()}>
            Log In
          </Button>
        </>
      )}
      {isAuthenticated && (
        <>
          <Button variant="contained" color="secondary" onClick={handleLogout}>
            Log Out
          </Button>
        </>
      )}
    </Box>
  );
};
import { AxiosRequestConfig } from "axios";
import { ApiResponse } from "../models/api-response";
import { createApiCallerWithToken } from "./external-api.service";
import { useAuthToken } from "../hooks/useAuthToken";
import { useNotification } from "../hooks/useNotification";
import { useState } from "react";
import { SEARCH_OPTIONS } from "../apiRoutes";
import { SearchOption } from "../models/api/SearchOption"; // Import the Item and Category types

const apiServerUrl = process.env.REACT_APP_API_URL;

export const useSearchService = () => {
  const token = useAuthToken();
  const { showNotification } = useNotification();
  const [authAttempted, setAuthAttempted] = useState(false);

  const callApiWithToken = async (options: { config: AxiosRequestConfig }): Promise<ApiResponse> => {
    if (authAttempted) {
      showNotification("Authorization already attempted and failed", "error");
      return Promise.reject("Authorization already attempted and failed");
    }

    if (!token) {
      showNotification("You must log in to access this resource", "error");
      setAuthAttempted(true);
      return Promise.reject("Token is null");
    }

    const apiCaller = createApiCallerWithToken(token);
    try {
      return await apiCaller(options);
    } catch (error) {
      console.error("API call error:", error);
      showNotification("Failed to fetch data from the server. Please try again later.", "error");
      return apiCaller(options);
    }
  };

  const getSearches = async (): Promise<ApiResponse> => {
    const config: AxiosRequestConfig = {
      url: `${apiServerUrl}${SEARCH_OPTIONS}/SearchOptions`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };

    return callApiWithToken({ config });
  };

  const fetchSearchOptions = async (): Promise<SearchOption[]> => {
    const response = await getSearches();
    if (response && Array.isArray(response.data)) return response.data as SearchOption[];

    throw new Error("Failed to fetch search options");
  };

  const putSearchOption = (searchOption: SearchOption) => {
    const config: AxiosRequestConfig = {
      url: `${apiServerUrl}${SEARCH_OPTIONS}/UpdateSearchOption`,
      method: "PUT",
      headers: {
        "content-type": "application/json",
      },
      data: searchOption,
    };

    return callApiWithToken({ config });
  };

  const deleteSearchOption = (id: number) => {
    const config: AxiosRequestConfig = {
      url: `${apiServerUrl}${SEARCH_OPTIONS}/DeleteSearchOption/${id}`,
      method: "DELETE",
      headers: {
        "content-type": "application/json",
      },
    };

    return callApiWithToken({ config });
  };

  const postSearchOption = (searchOption: SearchOption) => {
    const config: AxiosRequestConfig = {
      url: `${apiServerUrl}${SEARCH_OPTIONS}/AddSearchOption`,
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      data: searchOption,
    };

    return callApiWithToken({ config });
  };

  return {
    getSearches,
    fetchSearchOptions,
    putSearchOption,
    deleteSearchOption,
    postSearchOption,
  };
};
// FILE: RoutesProvider.tsx
import React, { createContext, useContext, useEffect, useState, useCallback } from "react";
import { getFilteredRoutes, routesConfig } from "../routesConfig";
import { useToken } from "../context/TokenProvider";

interface RoutesContextType {
  routes: typeof routesConfig | null;
  routesLoading: boolean;
  loadRoutes: () => Promise<void>;
}

const RoutesContext = createContext<RoutesContextType | undefined>(undefined);

export const RoutesProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [routes, setRoutes] = useState<typeof routesConfig | null>(null);
  const [routesLoading, setRoutesLoading] = useState(true);
  const { roles, setOnTokenUpdate } = useToken();

  const loadRoutes = useCallback(async () => {
    setRoutesLoading(true);
    await new Promise(resolve => setTimeout(resolve, 1000));
    const filteredRoutes = getFilteredRoutes(roles);
    setRoutes(filteredRoutes);
    setRoutesLoading(false);
  }, [roles]);

  useEffect(() => {
    loadRoutes();
  }, [loadRoutes]);

  useEffect(() => {
    setOnTokenUpdate(loadRoutes);
  }, [setOnTokenUpdate, loadRoutes]);

  return (
    <RoutesContext.Provider value={{ routes, routesLoading, loadRoutes }}>
      {children}
    </RoutesContext.Provider>
  );
};

export const useRoutes = () => {
  const context = useContext(RoutesContext);
  if (!context) {
    throw new Error("useRoutes must be used within a RoutesProvider");
  }
  return context.routes;
};

export const useRoutesLoading = () => {
  const context = useContext(RoutesContext);
  if (!context) {
    throw new Error("useRoutesLoading must be used within a RoutesProvider");
  }
  return context.routesLoading;
};

export const useLoadRoutes = () => {
  const context = useContext(RoutesContext);
  if (!context) {
    throw new Error("useLoadRoutes must be used within a RoutesProvider");
  }
  return context.loadRoutes;
};
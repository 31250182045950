import React, { useState, useEffect, useCallback } from "react";
import {
  Grid,
  FormControl,
  FormControlLabel,
  Switch,
  Button,
  Typography,
  Box,
  TextField,
} from "@mui/material";
import { useFinderService } from "../../services/finder.service";
import { Item, Category } from "../../models/api/item";
import FinderTable from "./FinderTable";
import { v4 as uuidv4 } from "uuid";
import { useNotification } from "../../hooks/useNotification";
import { PageLayout } from "../../components/page-layout";

interface FinderProps {
  env: string;
}

const countdownIntervalInSeconds = 45;

export const FinderPage: React.FC<FinderProps> = ({ env }) => {
  const { fetchDataFromApi } = useFinderService();
  const [data, setData] = useState<Item[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [countdown, setCountdown] = useState(countdownIntervalInSeconds);
  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const [sessionId, setSessionId] = useState<string>("");
  const { showNotification } = useNotification();

  const fetchData = useCallback(async () => {
    try {
      let fetchedData: Item[] = await fetchDataFromApi(sessionId);

      const existingItemIds = new Set(data.map((item) => item.ItemId));
      const uniqueFetchedData = fetchedData.filter(
        (item) => !existingItemIds.has(item.ItemId)
      );

      const mergedData = [...data, ...uniqueFetchedData];
      setData(mergedData);

      const uniqueCategories: { [key: string]: Category } = {};
      mergedData.forEach((item) => {
        item.Categories.forEach((category) => {
          if (!uniqueCategories[category.CategoryId]) {
            uniqueCategories[category.CategoryId] = category;
          }
        });
      });
      setCategories(Object.values(uniqueCategories));
    } catch (error) {
      console.error("Error fetching items:", error);
      showNotification(
        "Error fetching items. Please try again later.",
        "error"
      );
    }
  }, [sessionId, data, fetchDataFromApi, showNotification]);

  const onSwitchAction = () => {
    if (!sessionId) {
      showNotification("Please start a session before searching.", "error");
      return;
    }
    setIsSwitchOn(!isSwitchOn);

    if (!isSwitchOn) {
      fetchData(); // Fetch data immediately when the switch is turned on
    }
  };

  const startSession = () => {
    if (!sessionId) {
      const newSessionId = uuidv4();
      setSessionId(newSessionId);
      sessionStorage.setItem("sessionId", newSessionId); // Store the session ID in sessionStorage
      sessionStorage.setItem("items", JSON.stringify(data)); // Store the items in sessionStorage
    }
  };

  const stopSession = async () => {
    setSessionId("");
    sessionStorage.removeItem("sessionId");
    sessionStorage.removeItem("items");
    setIsSwitchOn(false);
    setData([]);
    setCountdown(countdownIntervalInSeconds);
  };

  const clearDataAndResetCountdown = () => {
    setData([]);
    // setCountdown(countdownIntervalInSeconds);
  };

  useEffect(() => {
    const savedSessionId = sessionStorage.getItem("sessionId");
    const savedItems = sessionStorage.getItem("items");

    if (savedSessionId) {
      setSessionId(savedSessionId);
    }

    if (savedItems) {
      setData(JSON.parse(savedItems));
    }
  }, []);

  useEffect(() => {
    if (sessionId) {
      sessionStorage.setItem("items", JSON.stringify(data)); // Update items in sessionStorage whenever items change
    }
  }, [data, sessionId]);

  // Manage the countdown timer and data fetching
  useEffect(() => {
    let countdownInterval: NodeJS.Timeout | null = null;

    if (isSwitchOn) {
      // fetchData(); // Fetch data immediately when the switch is turned on

      // Start the countdown and fetch data every 45 seconds
      countdownInterval = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown === 1) {
            fetchData(); // Fetch data after 45 seconds
            return countdownIntervalInSeconds; // Reset the countdown to 45 seconds
          }
          return prevCountdown - 1; // Decrement the countdown every second
        });
      }, 1000); // Decrease the countdown every second
    }

    // Cleanup function to clear the interval when the component unmounts or switch is turned off
    return () => {
      if (countdownInterval) {
        clearInterval(countdownInterval);
      }
    };
  }, [isSwitchOn, fetchData]);

  return (
    <PageLayout>
      <Box>
        <Box>
          <h1>Finder</h1>
          {/* Countdown Info */}
          {isSwitchOn && (
            <Grid item xs={12} sm={3}>
              <FormControl variant="outlined">
                <Typography variant="body1" sx={{ marginTop: 2, marginBottom: 2 }}>
                  <b>Next data fetch in {countdown} seconds</b>
                </Typography>
              </FormControl>
            </Grid>
          )}
        </Box>

        <Grid container spacing={2} direction="row" alignItems="center">
          {/* Session ID Input */}
          <Grid item xs={12} sm={3}>
            <TextField
              label="Session ID"
              variant="outlined"
              value={sessionId}
              onChange={(e) => setSessionId(e.target.value)}
              fullWidth
              disabled
            />
          </Grid>

          {/* Start/Stop Session Button */}
          <Grid item xs={12} sm={2}>
            <Button
              variant="contained"
              color={sessionId ? "secondary" : "primary"}
              onClick={sessionId ? stopSession : startSession}
              fullWidth
            >
              {sessionId ? "Stop Session" : "Start Session"}
            </Button>
          </Grid>

          {/* Switch */}
          <Grid item xs={12} sm={2}>
            <FormControl component="fieldset">
              <FormControlLabel
                control={
                  <Switch
                    checked={isSwitchOn}
                    onChange={onSwitchAction}
                    name="custom-switch"
                  />
                }
                label="Start searching"
              />
            </FormControl>
          </Grid>

          {/* Clear Data Button */}
          <Grid item xs={12} sm={2}>
            <Button
              variant="outlined"
              color="primary"
              onClick={clearDataAndResetCountdown}
              fullWidth
            >
              Clear Data
            </Button>
          </Grid>
        </Grid>

        {/* Data Table or Loading */}
        <Box sx={{ position: "absolute", left: 0, right: 0 }}>
          <FinderTable data={data} categories={categories} />
        </Box>
      </Box>
    </PageLayout>
  );
};

import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PageLayout } from "../../components/page-layout";
import { useToken, handleToken } from "../../context/TokenProvider";
import { useLoadRoutes } from "../../context/RoutesProvider";
import { Button } from "@mui/material";

export const CallbackPage: React.FC = () => {
  const { isAuthenticated, error, isLoading, getAccessTokenSilently, logout } =
    useAuth0();
  const navigate = useNavigate();
  const { setToken, setRoles, setUser, onTokenUpdateRef } = useToken();
  const loadRoutes = useLoadRoutes();
  const [emailVerificationError, setEmailVerificationError] = useState(false);

  const handleLogout = () => {
    logout({ logoutParams: { returnTo: window.location.origin } } as any);
  };

  useEffect(() => {
    const fetchAndLoadRoutes = async () => {
      if (isAuthenticated) {
        try {
          const accessToken = await getAccessTokenSilently();
          localStorage.setItem("accessToken", accessToken);
          handleToken(
            accessToken,
            setToken,
            setRoles,
            setUser,
            onTokenUpdateRef
          );
          await loadRoutes();
          navigate("/");
        } catch (error) {
          if (
            error instanceof Error &&
            error.message.includes(
              "Please verify your email before continuing."
            )
          ) {
            setEmailVerificationError(true);
          }
        }
      }
    };

    fetchAndLoadRoutes();
  }, [
    isAuthenticated,
    navigate,
    getAccessTokenSilently,
    setToken,
    setRoles,
    setUser,
    onTokenUpdateRef,
    loadRoutes,
  ]);

  if (isLoading) {
    return null;
  }

  if (error || emailVerificationError) {
    return (
      <PageLayout>
        <div>
          <h1>Error</h1>
          <p>{error?.message}</p>
          {emailVerificationError && (
            <div>
              <p>
                Please verify your email before continuing. A new email
                verification mail has been sent. Please check your inbox and
                spam folder.
              </p>{" "}
              <Button
                variant="contained"
                color="secondary"
                onClick={handleLogout}
              >
                Logout
              </Button>
            </div>
          )}
        </div>
      </PageLayout>
    );
  }

  return null;
};

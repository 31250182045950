import { AxiosRequestConfig } from "axios";
import { ApiResponse } from "../models/api-response";
import { createApiCallerWithToken } from "./external-api.service";
import { useAuthToken } from "../hooks/useAuthToken";
import { useNotification } from "../hooks/useNotification";
import { useState } from "react";
import { RegistrationCode } from "src/models/api/registration-code";

const apiServerUrl = process.env.REACT_APP_API_URL;

export const useMessageService = () => {
  const token = useAuthToken();
  const { showNotification } = useNotification();
  const [authAttempted, setAuthAttempted] = useState(false);

  const callApiWithToken = async (options: { config: AxiosRequestConfig }): Promise<ApiResponse> => {
    if (authAttempted) {
      showNotification("Authorization already attempted and failed", "error");
      return Promise.reject("Authorization already attempted and failed");
    }

    if (!token) {
      showNotification("You must log in to access this resource", "error");
      setAuthAttempted(true);
      return Promise.reject("Token is null");
    }

    const apiCaller = createApiCallerWithToken(token);
    return apiCaller(options);
  };

  const getPublicResource = async (): Promise<ApiResponse> => {
    const config: AxiosRequestConfig = {
      url: `${apiServerUrl}/api/v1/user/messages/public`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };

    return callApiWithToken({ config });
  };

  const getProtectedResource = async (): Promise<ApiResponse> => {
    const config: AxiosRequestConfig = {
      url: `${apiServerUrl}/api/v1/user/messages/protected`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };

    return callApiWithToken({ config });
  };

  const getAdminResource = async (): Promise<ApiResponse> => {
    const config: AxiosRequestConfig = {
      url: `${apiServerUrl}/api/v1/user/messages/admin`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };

    return callApiWithToken({ config });
  };

  const applyForRole = async (registrationCode: string): Promise<ApiResponse> => {
    const config: AxiosRequestConfig = {
      url: `${apiServerUrl}/api/v1/auth/apply-for-role`,
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      data: registrationCode
    };

    return callApiWithToken({ config });
  };

  const getRegistrationCodes = async (): Promise<ApiResponse> => {
    const config: AxiosRequestConfig = {
      url: `${apiServerUrl}/api/v1/auth/registration-codes`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };

    return callApiWithToken({ config });
  };

  const fetchRegistrationCodes = async (): Promise<RegistrationCode[]> => {
    const response = await getRegistrationCodes();
    if (response && Array.isArray(response.data)) return response.data as RegistrationCode[];

    throw new Error("Failed to fetch registration codes");
  };

  const generateCode = async () => {
    const config: AxiosRequestConfig = {
      url: `${apiServerUrl}/api/v1/auth/generate-registration-code`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      }
    };

    return callApiWithToken({ config });
  };

  const markCodeAsUsed = async (registrationCode: string): Promise<ApiResponse> => {
    const config: AxiosRequestConfig = {
      url: `${apiServerUrl}/api/v1/auth/mark-registration-code-as-used`,
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      data: registrationCode
    };

    return callApiWithToken({ config });
  };

  return {
    getPublicResource,
    getProtectedResource,
    getAdminResource,
    applyForRole,
    fetchRegistrationCodes,
    generateCode,
    markCodeAsUsed
  };
};
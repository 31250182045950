import React, { useState } from "react";
import { Container, Box, Typography, TextField, Button } from "@mui/material";
import { useNotification } from "../../../hooks/useNotification"; // Assuming you have a notification hook
import { useMessageService } from "../../../services/auth.service"; // Import the service
import { useNavigate } from "react-router-dom"; // Import useNavigate

export const ApplyRolePage: React.FC = () => {
  const [registrationCode, setRegistrationCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(""); // State for error message
  const { showNotification } = useNotification(); // Notification for success/error feedback
  const { applyForRole } = useMessageService(); // Use the service
  const navigate = useNavigate(); // Initialize useNavigate

  const registrationCodePattern =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;

  const handleSubmit = async () => {
    if (!registrationCode.trim()) {
      setError("Please enter a valid registration code.");
      return;
    }

    if (!registrationCodePattern.test(registrationCode)) {
      setError("Invalid registration code format.");
      return;
    }

    setLoading(true);
    setError(""); // Clear error message on submit

    try {
      // Use the service to apply for role
      const response = await applyForRole(registrationCode);

      if (!response.success) {
        throw new Error("Failed to apply role. Please try again.");
      }

      showNotification("Role successfully applied!", "success");
      setRegistrationCode(""); // Clear input after success
    } catch (error: any) {
      showNotification(error.message || "An error occurred.", "error");
    } finally {
      setLoading(false);
      window.location.reload(); // Hard refresh
      window.location.href = "/"; // Redirect to homepage
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRegistrationCode(e.target.value);
    if (error) {
      setError(""); // Clear error message when user starts typing
    }
  };

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          textAlign: "center",
        }}
      >
        <Typography variant="h4" gutterBottom>
          Apply for a New Role
        </Typography>
        <Typography variant="body1" gutterBottom>
          Do you have a registration code? Enter it below to apply for a new
          role. After applying, you will be redirected to the start guide page,
          and use the finder tool.
        </Typography>
        <Box component="form" noValidate autoComplete="off" sx={{ mt: 3 }}>
          <TextField
            fullWidth
            variant="outlined"
            label="Registration Code"
            value={registrationCode}
            onChange={handleInputChange}
            error={!!error}
            helperText={error}
            disabled={loading}
          />
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSubmit}
            sx={{ mt: 3 }}
            disabled={loading}
          >
            {loading ? "Submitting..." : "Submit"}
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

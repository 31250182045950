import { withAuthenticationRequired } from "@auth0/auth0-react";
import React, { ComponentType } from "react";
import { PageLoader } from "./page-loader";
import { useToken } from "../context/TokenProvider"; // Import useToken to access roles
import { useNavigate } from "react-router-dom";

interface AuthenticationGuardProps {
  component: ComponentType;
  requiredRole?: string; // Optional prop to specify required role
}

export const AuthenticationGuard: React.FC<AuthenticationGuardProps> = ({
  component,
  requiredRole, // Optional required role for access control
}) => {
  const { roles } = useToken(); // Get roles from TokenContext
  const navigate = useNavigate();

  // Check if the user has the required role
  if (requiredRole && !roles.includes(requiredRole)) {
    navigate("/"); // Redirect to home or any other page if not authorized
    return null; // Prevent rendering the component if not authorized
  }

  // Use withAuthenticationRequired to ensure authentication
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => (
      <div className="page-layout">
        <PageLoader />
      </div>
    ),
  });

  return <Component />;
};

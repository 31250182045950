import React, { useMemo, useState, useEffect } from "react";
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  Link,
  Paper,
  TablePagination,
  TextField,
  useMediaQuery,
  useTheme,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Checkbox,
  ListItemText,
  Box,
  Chip,
  SelectChangeEvent,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Grid,
  Fab,
  useScrollTrigger,
  Zoom,
  Fade,
  Toolbar,
} from "@mui/material";
import { styled } from "@mui/system";
import Jumbotron from "../../assets/camera.png";
import { Item, Category } from "../../models/api/item"; // Import the Item and Category types
import KeyboardArrowUpIcon from "@mui/icons-material/ArrowUpward";
import KeyboardArrowDownIcon from "@mui/icons-material/ArrowDownward";
import IgnoredCategorieIds from "../../models/api/ignoredCategoriesIds";
import { PageLayout } from "src/components/page-layout";

const FullWidthContainer = styled(Container)({
  width: "100%",
  padding: 0,
  margin: 0,
});

const FullWidthTableContainer = styled(TableContainer)(({ theme }) => ({
  maxHeight: 600,
  width: "100%",
  [theme.breakpoints.down("sm")]: {
    width: "100vw",
    overflowX: "auto",
  },
}));

interface DataTableProps {
  data: Item[];
  categories: Category[];
}

const headCells = [
  { id: "Image", label: "Image" },
  { id: "Title", label: "Title" },
  { id: "ShortDescription", label: "Price" },
  { id: "Condition", label: "Details" },
  { id: "ItemCreationDate", label: "Creation Date" },
  { id: "ItemEndDate", label: "End Date" },
];

interface Props {
  children?: React.ReactElement<any>;
}

function ScrollToTop(props: Props) {
  const { children } = props;

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const anchor = (
      (event.target as HTMLDivElement).ownerDocument || document
    ).querySelector("#back-to-top-anchor");

    if (anchor) {
      anchor.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  return (
    <Box
      onClick={handleClick}
      role="presentation"
      sx={{ position: "fixed", bottom: 150, right: 16 }}
    >
      {children}
    </Box>
  );
}

function ScrollToBottom(props: Props) {
  const { children } = props;

  const handleClick = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };

  return (
    <Box
      onClick={handleClick}
      role="presentation"
      sx={{ position: "fixed", bottom: 90, right: 0 }}
    >
      {children}
    </Box>
  );
}

const DataTableHead: React.FC<{
  orderBy: string;
  onRequestSort: (property: string) => void;
  isSmallScreen: boolean;
}> = ({ orderBy, onRequestSort, isSmallScreen }) => {
  const createSortHandler = (property: string) => () => {
    onRequestSort(property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells
          .filter((headCell) => !isSmallScreen || headCell.id === "Image")
          .map((headCell) => (
            <TableCell key={headCell.id}>
              <TableSortLabel
                active={orderBy === headCell.id}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            </TableCell>
          ))}
      </TableRow>
    </TableHead>
  );
};

const DataTable: React.FC<DataTableProps> = ({ data }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const [order, setOrder] = useState<"asc" | "desc">();
  const [orderBy, setOrderBy] = useState<string>("ItemCreationDate");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [selectedConditions, setSelectedConditions] = useState<string[]>([]);

  useEffect(() => {
    const maxPage = Math.max(0, Math.ceil(data.length / rowsPerPage) - 1);
    if (page > maxPage) {
      setPage(maxPage);
    }
  }, [data.length, rowsPerPage, page]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
    setPage(0); // Reset to first page on search
  };

  const handleConditionChange = (event: SelectChangeEvent<string[]>) => {
    setSelectedConditions(event.target.value as string[]);
    setPage(0); // Reset to first page on condition change
  };

  const filteredData = useMemo(() => {
    return data.filter(
      (item) =>
        (selectedConditions.length === 0 ||
          selectedConditions.includes(item.Condition)) &&
        !item.Categories.some((category: { CategoryId: string }) =>
          IgnoredCategorieIds.includes(category.CategoryId)
        ) &&
        ((item.Title &&
          item.Title.toLowerCase().includes(searchQuery.toLowerCase())) ||
          (item.ItemId &&
            item.ItemId.toLowerCase().includes(searchQuery.toLowerCase())))
    );
  }, [data, searchQuery, selectedConditions]);

  const sortedData = useMemo(() => {
    return [...filteredData].sort((a, b) => {
      const dateA = new Date(a.ItemCreationDate).getTime();
      const dateB = new Date(b.ItemCreationDate).getTime();
      return order === "asc" ? dateA - dateB : dateB - dateA;
    });
  }, [filteredData, order]);

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedData = useMemo(() => {
    return sortedData.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
  }, [sortedData, page, rowsPerPage]);

  return (
    <PageLayout>
      <Box>
        <div id="back-to-top-anchor" className="hidden" />
        <ScrollToTop>
          <Fab color="primary" size="small" className="fabUp">
            <KeyboardArrowUpIcon />
          </Fab>
        </ScrollToTop>
        <ScrollToBottom>
          <Fab color="primary" size="small" className="fab">
            <KeyboardArrowDownIcon />
          </Fab>
        </ScrollToBottom>
        <TextField
          label="Search"
          variant="outlined"
          value={searchQuery}
          onChange={handleSearchChange}
          fullWidth
          margin="normal"
        />
        <FormControl fullWidth margin="normal">
          <InputLabel>Condition</InputLabel>
          <Select
            multiple
            value={selectedConditions}
            onChange={handleConditionChange}
            renderValue={(selected) => (selected as string[]).join(", ")}
            label="Condition"
          >
            {Array.from(new Set(data.map((item) => item.Condition))).map(
              (condition) => (
                <MenuItem key={condition} value={condition}>
                  <Checkbox
                    checked={selectedConditions.indexOf(condition) > -1}
                  />
                  <ListItemText primary={condition} />
                </MenuItem>
              )
            )}
          </Select>
        </FormControl>
        <FullWidthTableContainer
          sx={{ maxHeight: "fit-content", width: "100%!important" }}
        >
          <Table stickyHeader>
            <DataTableHead
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              isSmallScreen={isSmallScreen}
            />
            <TableBody>
              {paginatedData.map((item) => {
                const url = new URL(item.ItemWebUrl);
                const baseUrl = `${url.origin}${url.pathname}`;

                return (
                  <TableRow key={item.ItemId}>
                    {isSmallScreen ? (
                      <Card sx={{ padding: 0 }}>
                        <CardContent
                          sx={{ padding: 0, paddingBottom: "10px!important" }}
                        >
                          <Box
                            sx={{
                              border: 1,
                              borderColor: "divider",
                              padding: 0,
                              borderRadius: 2,
                              boxShadow: 3,
                            }}
                          >
                            <Grid container spacing={1}>
                              <Grid
                                item
                                xs={4}
                                sm={2}
                                style={{
                                  minWidth: "200px",
                                }}
                              >
                                {item.Image && item.Image.ImageUrl ? (
                                  <Link
                                    href={baseUrl}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <CardMedia
                                      component="img"
                                      style={{
                                        height: "100%",
                                        width: "100%",
                                        objectFit: "contain",
                                      }}
                                      image={item.Image.ImageUrl}
                                      alt={`Item ${item.ItemId}`}
                                    />
                                  </Link>
                                ) : (
                                  <CardMedia
                                    component="img"
                                    style={{
                                      height: "100%",
                                      width: "100%",
                                      objectFit: "contain",
                                    }}
                                    image={Jumbotron}
                                    alt="Placeholder"
                                  />
                                )}
                              </Grid>
                              <Grid
                                item
                                xs={8}
                                sm={10}
                                style={{ flex: 1, marginBottom: "6px" }}
                              >
                                <CardHeader
                                  title={
                                    <Link
                                      href={baseUrl}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {item.Title || "N/A"}
                                    </Link>
                                  }
                                  titleTypographyProps={{ variant: "h6" }}
                                  sx={{ padding: 0 }}
                                />
                                <Typography variant="body1">
                                  <Chip
                                    label={
                                      item.Price &&
                                      item.Price.Currency &&
                                      item.Price.Value
                                        ? `${item.Price.Currency}: ${item.Price.Value}`
                                        : "N/A"
                                    }
                                  />
                                </Typography>
                                <Box>{item.Condition || "N/A"}</Box>
                                <Typography variant="body2">
                                  {item.ListingMarketplaceId || "N/A"}
                                </Typography>
                                <Typography variant="body2">
                                  {item.BuyingOptions &&
                                  item.BuyingOptions.length > 0
                                    ? item.BuyingOptions.join(", ")
                                    : "N/A"}
                                </Typography>
                                <Typography variant="body2">
                                  {item.ItemId
                                    ? item.ItemId.split("|")[1]
                                    : "N/A"}
                                </Typography>
                                <Typography variant="body2">
                                  {item.ItemCreationDate
                                    ? new Date(
                                        item.ItemCreationDate
                                      ).toLocaleString()
                                    : "N/A"}
                                </Typography>
                                <Typography variant="body2">
                                  {item.ItemEndDate
                                    ? new Date(
                                        item.ItemEndDate
                                      ).toLocaleString()
                                    : "N/A"}
                                </Typography>
                                <Typography variant="body2">
                                  {item.Categories.map((category) => (
                                    <span key={category.CategoryId}>
                                      {category.CategoryId}:{" "}
                                      {category.CategoryName}
                                    </span>
                                  ))}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Box>
                        </CardContent>
                      </Card>
                    ) : (
                      <>
                        <TableCell>
                          {item.Image && item.Image.ImageUrl ? (
                            <Link
                              href={baseUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                style={{ height: "150px", width: "150px" }}
                                src={item.Image.ImageUrl}
                                alt={`Item ${item.ItemId}`}
                              />
                            </Link>
                          ) : (
                            <img
                              style={{ height: "150px", width: "150px" }}
                              src={Jumbotron}
                              alt="Placeholder"
                            />
                          )}
                        </TableCell>
                        <TableCell>
                          <Link
                            href={baseUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Typography variant="h5">
                              {item.Title || "N/A"}
                            </Typography>
                          </Link>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body1">
                            <Chip
                              label={
                                item.Price &&
                                item.Price.Currency &&
                                item.Price.Value
                                  ? `${item.Price.Currency}: ${item.Price.Value}`
                                  : "N/A"
                              }
                            />
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2">
                            {item.ItemId ? item.ItemId.split("|")[1] : "N/A"}
                          </Typography>
                          <Typography variant="body2">
                            {item.Condition || "N/A"}
                          </Typography>
                          <Typography variant="body2">
                            {item.ListingMarketplaceId || "N/A"}
                          </Typography>
                          <Typography variant="body2">
                            {item.BuyingOptions && item.BuyingOptions.length > 0
                              ? item.BuyingOptions.join(", ")
                              : "N/A"}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2">
                            {item.ItemCreationDate
                              ? new Date(item.ItemCreationDate).toLocaleString()
                              : "N/A"}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2">
                            {item.ItemEndDate
                              ? new Date(item.ItemEndDate).toLocaleString()
                              : "N/A"}
                          </Typography>
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </FullWidthTableContainer>
        <TablePagination
          sx={{
            "& .MuiTablePagination-toolbar": {
              display: "flex",
              flexWrap: "wrap", // Ensure wrapping for small screens
              justifyContent: "space-between",
              padding: { xs: 0, sm: "default" }, // Remove padding on mobile
              overflow: "hidden", // Add overflow hidden
            },
            "& .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows":
              {
                display: "block", // Stack the label and rows under each other
                textAlign: { xs: "center", sm: "left" }, // Center on small screens
                marginBottom: { xs: "8px", sm: "0" }, // Add spacing between them
              },
            "& .MuiTablePagination-actions": {
              display: "flex",
              justifyContent: { xs: "center", sm: "flex-end" }, // Center on small screens, right-align on larger
              width: { xs: "100%", sm: "auto" }, // Full width on small screens
              marginTop: { xs: "8px", sm: "0" }, // Add top margin on small screens
            },
          }}
          rowsPerPageOptions={[10, 25, 50, 100, 250]}
          component="div"
          count={sortedData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </PageLayout>
  );
};

export default DataTable;

import React from 'react';
import { Button, Container, Typography, Box, Paper, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export const StartPage: React.FC = () => {
    const navigate = useNavigate();

    return (
        <Container maxWidth="xl" sx={{ mt: 6 }}>
            <Paper elevation={6} sx={{ p: 4, borderRadius: 2 }}>
                <Typography variant="h4" component="h1" gutterBottom align="center">
                    🎉 Congrats on Your Approval!
                </Typography>
                <Typography variant="body1" align="center" paragraph>
                    Welcome aboard! As a newly approved user, you’re now ready to explore the platform’s key features. Please note we’re in a testing phase, and improvements are continuously being made.
                </Typography>

                <Grid container spacing={4} sx={{ mt: 3 }}>
                    <Grid item xs={12} sm={6}>
                        <Box sx={{ p: 3, border: '1px solid', borderColor: 'divider', borderRadius: 2, height: '100%' }}>
                            <Typography variant="h5" gutterBottom>
                                🔍 SearchOptions
                            </Typography>
                            <Typography variant="body1" paragraph>
                                Use the SearchOptions page to input keywords and apply filters to find items on eBay that match your interests.
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                - <strong>Keyword Search:</strong> Input keywords to discover items.
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                - <strong>Filters:</strong> Customize your search by conditions, location, and more.
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                - <strong>Save Preferences:</strong> Easily reuse your saved search criteria.
                            </Typography>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => navigate('/searchOptions')}
                                fullWidth
                                sx={{ mt: 2 }}
                            >
                                Go to SearchOptions
                            </Button>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Box sx={{ p: 3, border: '1px solid', borderColor: 'divider', borderRadius: 2, height: '100%' }}>
                            <Typography variant="h5" gutterBottom>
                                🔁 Finder
                            </Typography>
                            <Typography variant="body1" paragraph>
                                On the Finder page, you can set up an automated session that searches eBay for new listings every 45 seconds.
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                - <strong>Automatic Updates:</strong> Get real-time updates on new listings.
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                - <strong>Continuous Search:</strong> Your session runs until you manually stop it.
                            </Typography>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => navigate('/finder')}
                                fullWidth
                                sx={{ mt: 2 }}
                            >
                                Go to Finder
                            </Button>
                        </Box>
                    </Grid>
                </Grid>

                <Typography variant="body1" align="center" sx={{ mt: 4 }}>
                    Thank you for being part of our early phase. We appreciate your feedback as we work to make the platform even better!
                </Typography>
            </Paper>
        </Container>
    );
};

import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { ApiResponse } from "../models/api-response";
import { AppError } from "../models/app-error";

export const callExternalApi = async (options: {
  config: AxiosRequestConfig;
}): Promise<ApiResponse> => {
  const { config } = options;

  try {
    const response: AxiosResponse = await axios(config);
    const { data } = response;

    return {
      data,
      error: null,
      status: response.status,
      statusText: response.statusText,
      success: true,
    };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;

      const { response } = axiosError;

      let message = "http request failed";

      if (response && response.statusText) {
        message = response.statusText;
      }

      if (axiosError.message) {
        message = axiosError.message;
      }

      if (response && response.data && (response.data as AppError).message) {
        message = (response.data as AppError).message;
      }

      // Log the error details
      console.error("API call error:", message, response?.data);

      return {
        data: null,
        error: {
          message,
        },
        status: response?.status ?? null,
        statusText: response?.statusText ?? null,
        success: false,
      };
    }

    return {
      data: null,
      error: {
        message: (error as Error).message,
      },
      status: null,
      statusText: null,
      success: false,
    };
  }
};

export const createApiCallerWithToken = (token: string) => {
  return async (options: { config: AxiosRequestConfig }): Promise<ApiResponse> => {
    const { config } = options;

    if (token) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    return callExternalApi({ config });
  };
};
// FILE: App.tsx
import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { TokenProvider } from "./context/TokenProvider";
import { DarkModeProvider } from "./context/DarkModeContext";
import { RoutesProvider } from "./context/RoutesProvider";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { CssBaseline, useMediaQuery } from '@mui/material';
import { NavBar } from "./components/navigation/desktop/nav-bar";
import { MobileNavBar } from "./components/navigation/mobile/mobile-nav-bar";
import { PageLoader } from "./components/page-loader";
import { AuthenticationGuard } from "./components/authentication-guard";
import { useToken } from "./context/TokenProvider";
import { useRoutesLoading, useRoutes } from "./context/RoutesProvider";
import { useDarkMode } from './context/DarkModeContext';
import { NotificationProvider } from "./context/NotificationProvider"; // Import NotificationProvider

const lightTheme = createTheme({
  palette: {
    mode: 'light',
  },
});

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

export const App: React.FC = () => {
  const { isLoading: authLoading } = useAuth0();

  return (
    <NotificationProvider>
      <TokenProvider>
        <RoutesProvider>
          <DarkModeProvider>
            <AppContent authLoading={authLoading} />
          </DarkModeProvider>
        </RoutesProvider>
      </TokenProvider>
    </NotificationProvider>
  );
};

const AppContent: React.FC<{ authLoading: boolean }> = ({ authLoading }) => {
  const { loading: tokenLoading, roles } = useToken();
  const routesLoading = useRoutesLoading();
  const routes = useRoutes(); // Use useRoutes hook to get filtered routes
  const { darkMode, toggleDarkMode } = useDarkMode();
  const isMobile = useMediaQuery('(max-width:600px)');

  if (routesLoading || !routes) {
    return (
      <div className="page-layout">
        <PageLoader />
      </div>
    );
  }

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <CssBaseline />
      {isMobile ? (
        <MobileNavBar onToggleDarkMode={toggleDarkMode} darkMode={darkMode} />
      ) : (
        <NavBar onToggleDarkMode={toggleDarkMode} darkMode={darkMode} />
      )}
      <div id="app">
        <Routes>
          {routes?.map(({ path, component, roles: routeRoles }) => {
            if (!routeRoles || routeRoles.some(role => roles.includes(role))) {
              return (
                <Route
                  key={path}
                  path={path}
                  element={routeRoles ? <AuthenticationGuard component={component} /> : React.createElement(component)}
                />
              );
            }
            return null;
          })}
        </Routes>
      </div>
    </ThemeProvider>
  );
};
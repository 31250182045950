// FILE: TokenProvider.tsx
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
  useRef,
} from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { jwtDecode } from "jwt-decode";
import { useNotification } from "../hooks/useNotification";

interface User {
  name: string;
  email: string;
}

interface TokenContextProps {
  token: string | null;
  roles: string[];
  user: User | null;
  getToken: () => Promise<string>;
  refreshToken: () => Promise<void>;
  loading: boolean;
  setOnTokenUpdate: (callback: () => void) => void;
  setToken: (token: string) => void; // Add this line
  setRoles: (roles: string[]) => void; // Add this line
  setUser: (user: User) => void; // Add this line
  onTokenUpdateRef: React.MutableRefObject<() => void>; // Add this line
}

const TokenContext = createContext<TokenContextProps | undefined>(undefined);

const decodeRolesFromToken = (token: string): string[] => {
  const decoded: any = jwtDecode(token);
  return decoded["permissions"] || [];
};

const decodeUserFromToken = (token: string): User => {
  const decoded: any = jwtDecode(token);
  return {
    name: decoded["name"],
    email: decoded["email"],
  };
};

export const handleToken = (
  accessToken: string,
  setToken: (token: string) => void,
  setRoles: (roles: string[]) => void,
  setUser: (user: User) => void,
  onTokenUpdateRef: React.MutableRefObject<() => void>
) => {
  setToken(accessToken);
  setRoles(decodeRolesFromToken(accessToken));
  setUser(decodeUserFromToken(accessToken));
  onTokenUpdateRef.current();
};

export const TokenProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { isAuthenticated, getAccessTokenSilently, getAccessTokenWithPopup } =
    useAuth0();
  const [token, setToken] = useState<string | null>(null);
  const [roles, setRoles] = useState<string[]>([]);
  const [user, setUser] = useState<User | null>(null);
  const [authAttempted, setAuthAttempted] = useState(false);
  const [loading, setLoading] = useState(true);
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;
  const { showNotification } = useNotification();
  const initialFetchAttempted = useRef(false);
  const onTokenUpdateRef = useRef<() => void>(() => {});

  const fetchToken = async () => {
    if (initialFetchAttempted.current) return;
    initialFetchAttempted.current = true;

    try {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: audience,
        },
      });

      if (accessToken) {
        handleToken(accessToken, setToken, setRoles, setUser, onTokenUpdateRef);
      } else {
        throw new Error("Access token is undefined");
      }
    } catch (error: any) {
      if (error.error === "consent_required") {
        try {
          const accessToken = await getAccessTokenWithPopup({
            authorizationParams: {
              audience: audience,
            },
          });

          if (accessToken) {
            handleToken(accessToken, setToken, setRoles, setUser, onTokenUpdateRef);
          } else {
            throw new Error("Access token is undefined");
          }
        } catch (popupError: any) {
          if (popupError.message.includes("window.open returned `null`")) {
            showNotification(
              "Popup blocked. Please allow popups for this site. Refresh the page after allowing popups.",
              "error"
            );
          } else {
            showNotification("Failed to fetch token via popup", "error");
          }
          console.error(popupError);
        }
      } else {
        if (isAuthenticated) {
          showNotification("Failed to fetch token", "error");
        }
      }
    } finally {
      setAuthAttempted(true);
      setLoading(false);
    }
  };

  const refreshToken = async () => {
    setLoading(true);
    await fetchToken();
  };

  useEffect(() => {
    fetchToken();
  }, [
    isAuthenticated,
    authAttempted,
    getAccessTokenSilently,
    getAccessTokenWithPopup,
    audience,
    showNotification,
  ]);

  return (
    <TokenContext.Provider
      value={{
        token,
        roles,
        user,
        getToken: getAccessTokenSilently,
        refreshToken,
        loading,
        setOnTokenUpdate: (callback: () => void) => {
          onTokenUpdateRef.current = callback;
        },
        setToken, // Add this line
        setRoles, // Add this line
        setUser, // Add this line
        onTokenUpdateRef, // Add this line
      }}
    >
      {children}
    </TokenContext.Provider>
  );
};

export const useToken = () => {
  const context = useContext(TokenContext);
  if (context === undefined) {
    throw new Error("useToken must be used within a TokenProvider");
  }
  return context;
};
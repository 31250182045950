import React from "react";
import { PageLayout } from "../../components/page-layout";

export const NotFoundPage: React.FC = () => {
  return (
    <PageLayout>
      <div>
        <h1>
          Not Found
        </h1>
      </div>
    </PageLayout>
  );
};

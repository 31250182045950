import React from "react";
import { IconButton } from "@mui/material";
import { Brightness4, Brightness7 } from "@mui/icons-material";

interface DarkModeToggleProps {
  onToggleDarkMode: () => void;
  darkMode: boolean;
}

export const DarkModeToggle: React.FC<DarkModeToggleProps> = ({
  onToggleDarkMode,
  darkMode,
}) => {
  return (
    <IconButton color="inherit" onClick={onToggleDarkMode} sx={{ marginLeft: 2 }}>
      {darkMode ? <Brightness7 /> : <Brightness4 />}
    </IconButton>
  );
};